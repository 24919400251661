import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {AuthApiRequestsService} from "./auth-api-requests.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthApiRequestsService, public router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const allowedRoles = next.data.allowedRoles;
    console.log('CAN-ACTIVATE', allowedRoles);
    const isAuthorized = this.isAuthorized(allowedRoles);
    if (isAuthorized) {
      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['auth/login']);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  private isAuthorized(allowedRoles: string[]): boolean {
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    const user_auth_data = this.auth.getUserAuthData();
    if (user_auth_data && user_auth_data.user_role) {
      for (const user_role of user_auth_data.user_role) {
        for (const allowed_role of allowedRoles) {
          console.log('CHECK', {'role': user_role.name, 'allowed': allowed_role});
          if (allowed_role === user_role.name) {
            return true;
          }
        }
      }
    } else  {
      return false;
    }
    return false;
  }
}
